import preload from "./animations/preload";

$(document).ready(async function () {
    await preload();
    
    const navTimeline = gsap.timeline({});
    const itemTimeline = gsap.timeline({});
    const formOrderTimeline = gsap.timeline({});
    const formReviewTimeline = gsap.timeline({});
    const pricingFeatureTimeline = gsap.timeline({});

    navTimeline
        .from("#navigation li", {
            scaleY: 0,
            stagger: 0.3,
        })
        .to("#navigation li", {
            scaleY: 1,
            duration: 0.3,
            ease: "power2.inOut",
        });

    pricingFeatureTimeline
        .from("#pricing li", {
            x: "-100%",
            opacity: 0,
            stagger: 0.2,
        })
        .to("#pricings li", {
            x: 0,
            opacity: 1,
            duration: 0.25,
        });
});
