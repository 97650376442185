export async function delay(duration = 1000) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, duration);
    });
}

export function setTheme(theme) {
    localStorage.setItem("theme", theme);
    $("html").attr("class", "");
    $("html").toggleClass(theme);
}
export default async function apiRequest(url) {
    let token = $("meta[name='X-API-TOKEN']").attr("content");

    return await fetch(url, {
        headers: {
            "X-API-TOKEN": token,
        },
    });
}
