import "./config";
import "./bootstrap";
import "./animates";
import "./functionals/navigation";
import "./functionals/dropdown";
import "./functionals/carousel";
import "./functionals/accordion";
import "./functionals/search-field";
import "./functionals/order-page";

import WOW from "wowjs";
import { setTheme } from "./utils/functions";

$(document).ready(async function () {
    const theme = localStorage.getItem("theme") || "light";
    const pathname = window.location.pathname;

    setTheme(theme);

    setTimeout(() => {
        $(".alert").remove();
    }, 2000);

    $(".svg-storyset").on("contextmenu", function () {
        window.location.href = "https://storyset.com/online";
    });
    $("img").each(function () {
        $(this).on("error", function () {
            $(this)[0].src = "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png";
        });
    });

    $("#theme-toggler #toggler").on("click", function () {
        let $icon = $(this).find("i");

        setTheme($icon.hasClass("fa-sun") ? "dark" : "light");

        $icon.toggleClass("fa-sun");
        $icon.toggleClass("fa-moon");
    });

    $("#rating-star-choice button").on("click", function () {
        let value = $(this).attr("data-value");

        $("#star").attr("value", value);

        $("#rating-star-choice button").removeClass("bg-secondary");
        ["bg-sublight", "dark:bg-subdark"].forEach((item) => $("#rating-star-choice button").addClass(item));
        ["bg-sublight", "dark:bg-subdark"].forEach((item) => $(this).removeClass(item));

        $(this).addClass("bg-secondary");
    });

    $(`button[type="submit"]`).on("click", function () {
        $(this).css({
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
        });
        $(this).attr("disabled", true);
        $(this).html(`
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            ${$(this).text()}
        `);
    });

    for (let path of ["blogs", "pricings", "products"]) {
        if (pathname.split("/").indexOf(path) > -1) {
            let parameters = new URLSearchParams({
                action: "increase-visitor",
            });

            let response = await fetch(`${window.location.href}?${parameters}`, {
                method: "GET",
                headers: {
                    token: $("meta[name='X-API-TOKEN']").attr("content"),
                },
            });
            let data = await response.json();
        }
    }

    $("section").addClass("wow fadeInUp");
    $("section").addClass("wow fadeInUp");
    $("section").addClass("data-wow-delay", ".5s");
    
    $("#why-choose-us even ul li").addClass("data-wow-delay", ".5s");
    $("#why-choose-us even ul li").addClass("data-wow-duration", "1s");
    $("#why-choose-us even ul li:nth-child(odd)").addClass("wow slideInRIght");
    $("#why-choose-us even ul li:nth-child(even)").addClass("wow slideInLeft");
    new WOW.WOW().init();
});
