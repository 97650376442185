$(document).ready(function () {
    const navHeight = $("#navigation").height();

    /* scroll window */
    $(window).on("scroll", function () {
        if (window.scrollY > navHeight) {
            if ($("#navigation").hasClass("sticky-navigation")) return false;

            $("#navigation").addClass("sticky-navigation");
        } else {
            $("#navigation").removeClass("sticky-navigation");
        }
    });

    $("#btn-toggle-nav").on("click", function () {
        $(this).toggleClass("active");
        $("#navigation").toggleClass("active");
    });
});
