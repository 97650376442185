$(document).ready(function () {
    $(".accordion").eq(0).addClass("active");
    
    $(".accordion .accordion-label").on("click", function () {
        $(this).parent().toggleClass("active");
    });

    $(".accordion .accordion-label").on("mouseover", function () {
        $(this).parent().toggleClass("active");
    });
    $(`.accordion[data-open="true"]`).toggleClass("active");
});
