import { delay } from "../utils/functions.js";

export default async function preload() {
    return new Promise(async (resolve) => {
        const PRELOAD_SELECTOR = "#preload";
        const PRELOADER_SELECTOR = "#preloader";
        const PRELOADER_TEXT_SELECTOR = "#preloader-text";
        const PRELOAD_TIMELINE = gsap.timeline({});

        document.querySelector(PRELOADER_SELECTOR).classList.remove("hidden");
        document.querySelector(PRELOADER_TEXT_SELECTOR).classList.remove("animate-pulse");

        PRELOAD_TIMELINE.from(PRELOAD_SELECTOR, {
            y: "-100%",
            opacity: 0,
            duration: 0,
        })
            .to(PRELOAD_SELECTOR, {
                y: 0,
                opacity: 1,
                duration: 0.1,
            })
            .from(PRELOADER_SELECTOR, {
                scale: 0,
                duration: 0.5,
                x: 100,
                rotation: 180,
            })
            .to(PRELOADER_SELECTOR, {
                scale: 1,
                x: -10,
                rotation: 0,
                duration: 0.5,
            })
            .from(PRELOADER_TEXT_SELECTOR, {
                opacity: 0,
                x: -10,
                duration: 0.5,
            })
            .to(PRELOADER_TEXT_SELECTOR, {
                y: 0,
                x: 0,
                opacity: 1,
                duration: 0.5,
            })
            .to(PRELOADER_TEXT_SELECTOR, {
                y: 0,
                x: -10,
                opacity: 0,
                duration: 1,
            })
            .to(PRELOADER_SELECTOR, {
                y: 0,
                x: 100,
                rotation: -180,
                opacity: 0,
                duration: 0.5,
            })
            .to(PRELOAD_SELECTOR, {
                y: "100%",
                duration: 0.5,
            });
        let duration = PRELOAD_TIMELINE.totalDuration() * 1000;

        await delay(duration);
        $(PRELOAD_SELECTOR).remove();
        resolve();
    });
}
